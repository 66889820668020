/* eslint-disable react-compiler/react-compiler */
import { useRef } from 'react'
import { createPortal } from 'react-dom'

const id = 'cliniko-react-portal-legacy'

const Portal = ({ children }) => {
  const portalRoot = useRef(null)

  if (portalRoot.current === null) {
    portalRoot.current = document.getElementById(id)

    if (!portalRoot.current) {
      portalRoot.current = createPortalElement(id)
      document.body.appendChild(portalRoot.current)
    }
  }

  return createPortal(children, portalRoot.current)
}

const createPortalElement = id => {
  const element = document.createElement('div')
  element.setAttribute('id', id)
  element.style.cssText = `
    position: relative;
  `
  return element
}

export default Portal
